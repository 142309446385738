import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import ElementUI from 'element-ui';

import "./assets/scss/common.scss";
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueAxios, axios, VueCookie, VueCookie)
Vue.use(ElementUI)

//import AOS from 'aos'
//import '../node_modules/aos/dist/aos.css'
//Vue.use(AOS);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
