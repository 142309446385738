<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import env from "@/env"
export default {
  data() {
    return {
    }
  },
  mounted() {
    if(this.isMobile()) {
      window.location.href = env.mUrl
      //this.$router.push(env.mUrl)
    }
  },
  methods: {
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    }
  },
}
</script>

