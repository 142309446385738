import Vue from 'vue'
import VueRouter from 'vue-router'
import env from "@/env"

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'index',
		meta: {
			title: '长佶科技',
			type:'home'
		},
		component: () => import('../views/index.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/about.vue'),
		meta: {
			title: '关于我们',
			type:'about'
		}
	},
	{
		path: '/development',
		name: 'development',
		component: () => import('../views/development.vue'),
		meta: {
			title: '发展历程',
			type:'about'
		}
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news'),
		redirect: '/news/31',
		meta: {
			title: '新闻动态',
			type:'news'
		},
		children: [
			{
				path: ':id',
				name: 'newsPage',
				component: () => import('@/views/news/list'),
				meta: {
					title: '新闻动态',
					type:'news'
				}
			},
			{
				path: ':id/:detailId',
				name: 'newsDetails',
				component: () => import('@/views/news/details.vue'),
				meta: {
					title: '新闻动态',
					type:'news'
				}
			},
		]
	},


	{
		path: '/business',
		name: 'business',
		component: () => import('@/views/business'),
		redirect: '/business/36',
		meta: {
			title: '业务展示',
			type:'business'
		},
		children: [
			{
				path: ':id',
				name: 'businessC1',
				component: () => import('@/views/business/detail'),
				meta: {
					title: '新闻动态',
					type:'business'
				}
			},
			{
				path: ':id/:childId',
				name: 'businessC2',
				component: () => import('@/views/business/detail'),
				meta: {
					title: '新闻动态',
					type:'business'
				}
			}
		]
	},

	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/views/contact'),
		redirect: '/contact/43',
		meta: {
			title: '联系我们',
			type:'contact'
		},
		children: [
			{
				path: ':id',
				name: 'contactC1',
				component: () => import('@/views/contact/detail'),
				meta: {
					title: '联系我们',
					type:'contact'
				}
			},
			{
				path: ':id/:childId',
				name: 'contactC2',
				component: () => import('@/views/contact/detail'),
				meta: {
					title: '联系我们',
					type:'contact'
				}
			}
		]
	},

	{
		path: '/car',
		name: 'car',
		component: () => import('@/views/car'),
		redirect: '/car/list',
		children: [
			{
				path: 'list',
				name: 'carList',
				component: () => import('../views/car/list.vue'),
				meta: {
					title: '车源',
					type:'car'
				}
			},
			{
				path: 'detail/:id',
				name: 'carDetail',
				component: () => import('../views/car/detail.vue'),
				meta: {
					title: '车源',
					type:'car'
				}
			},
		]
	},


]
const router = new VueRouter({
	mode: "history",
	routes
})

router.beforeEach(async(to, from, next) => {
	let metaTitle = env.title
	if(to.meta.title){
		metaTitle = to.meta.title
	}
	document.title = metaTitle;

	let head = document.getElementsByTagName('head');
	let meta = document.createElement('meta');
	document.querySelector('meta[name="keywords"]').setAttribute('content', env.keywords)
	document.querySelector('meta[name="description"]').setAttribute('content', env.description)
	meta.content = to.meta.content;
	head[0].appendChild(meta)

	return next();
});


export default router
